@media only screen and (min-width: 480px) {
    .static-event {
        margin-bottom: 2rem;
        display: flex;    
    }

    .static-event-title {
        position: absolute;
        text-align: left;
        display: flex;
        flex-direction: column;
        max-height: 200px;
        left: 14px;
        bottom: 12px;
        height: auto;
        transition: left .5s ease-out,
                    bottom  .5s ease-out,
                    transform .5s ease-out,
                    width .5s ease-out;
    }

    .static-event-container {
        height: auto;
        display: flex;
        flex-direction: column;
        border-radius: 2rem;
        padding: 1.2rem;
        text-align: left;
        border-top: 4px solid var(--Umber-Border);
        border-left: 4px solid var(--Umber-Border);
        width: 2rem !important;
        transition: width .3s ease-in-out;
    }

    .static-event-container[data-is-active="true"] {
        width: 21.5rem !important;
    }

    .static-event-container[data-is-active="false"] .static-event-title-container {
        transform: rotate(90deg);
        width: fit-content;
        min-width: max-content;
    }
    
    .static-event-container[data-is-active="true"] .static-event-description-container {
        opacity: 1 !important;
        visibility: visible  !important;
        transition: visibility .3s ease-in-out .3s,
                    opacity .3s ease-in-out .3s !important;
    }

    .static-event-title-container {
        display: flex;
        flex-direction: column;
        height: auto;
        transform-origin: bottom left;
        transition: transform .3s ease-in-out,
                    width .3s ease-in-out;
    }
    .static-event-title-container h5[data-is-active="true"] {
      width: 21.5rem;
    }
    .static-event-title-container h5[data-is-active="false"] {
      width: 40rem;
      transition: width .3s ease-in;
    }
    .static-event-small-title {
        margin-bottom: .4rem !important;
    }

    .static-event-description-container {
        display: flex;
        max-width: 20rem;
        flex-wrap: wrap;
        height: auto;
        margin-top: 1rem;
        opacity: 0;
        visibility: hidden;
        /* transition: visibility 0 ease-in-out,
                    opacity .3s ease-in-out; */
    }

    .static-event-details-container[data-is-not-last="true"] {
        margin-bottom: 1rem;
    } 

    .static-event-details-container {
        display: flex;
        flex-direction: column;
        height: fit-content;
        min-width: 95%;
        background-color: var(--Wheat);
        padding: 1rem;
        padding-bottom: 0;
        border-radius: 2rem;
        max-height: calc(100% - 2rem);
        border-bottom: 4px solid var(--Umber-Border);
        border-right: 4px solid var(--Umber-Border);
    }

    .static-event-details-title {
        display: flex;

    }

    .static-event-details-ul {
        padding-left: 1.2rem;
    }

    .static-event-details-li {
    }
}


@media only screen and (max-width: 480px),
       only screen and (max-height: 480px) {
    .static-event {
        margin-bottom: 2rem;
        display: flex;    
    }

    .static-event-title {
        position: absolute;
        text-align: left;
        display: flex;
        flex-direction: column;
        width: max-content;
        max-height: 200px;
        left: 14px;
        bottom: 12px;
        height: auto;
        transition: left .5s ease-out,
                    bottom  .5s ease-out,
                    transform .5s ease-out,
                    width .5s ease-out;
    }

    .static-event-container {
        min-width: 90%;
        height: auto;
        display: flex;
        flex-direction: column;
        flex: .75 0;
        background-color: var(--Sage);
        border-radius: 2rem;
        padding: 1.2rem;
        text-align: left;
        min-width: 21.5rem;
        max-width: 21.5rem;
        border-top: 4px solid var(--Umber-Border);
        border-left: 4px solid var(--Umber-Border);
    }

    .static-event-title-container {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .static-event-description-container {
        display: flex;
        max-width: 20rem;
        flex-wrap: wrap;
        height: auto;
        margin-top: 1rem;
    }

    .static-event-details-container[data-is-not-last="true"] {
        margin-bottom: 1rem;
    } 

    .static-event-details-container {
        display: flex;
        flex-direction: column;
        height: fit-content;
        min-width: 95%;
        background-color: var(--Wheat);
        padding: 1rem;
        border-radius: 2rem;
        max-height: calc(100% - 2rem);
        border-bottom: 4px solid var(--Umber-Border);
        border-right: 4px solid var(--Umber-Border);
    }

    .static-event-details-title {
        display: flex;

    }

    .static-event-details-ul {
      padding-left: 2rem;

    }

    .static-event-details-li {
    }
}