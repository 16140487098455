@media only screen and (min-width: 480px) {
    .status-flag {
      position: absolute;
      right: 0;
      width: 20%;
    }
    .status-flag > img {
      width: 100%;
    }
    .static-contact {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .static-contact-form {
        background-color: var(--Brown-Sugar);
        border-radius: 2rem;
        display: flex;
        width: 70%;
        max-height: 68%;
        min-height: 67%;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }

    .static-contact-form-row-1 {
        display: flex;
        align-items: center;
        width: 70%;
        min-height: 10%;
        max-height: 10%;
    }

    .static-contact-email {
        border-top: 4px solid var(--Umber-Border);
        border-left: 4px solid var(--Umber-Border);
        display: flex;
        height: calc(100% - 4px);
        min-width: calc(100% - 4px);
        min-height: calc(100% - 4px);
        background-color: var(--Umber);
        border-radius: 1.2rem;
        align-items: center;
        justify-content: center;
    }

    .static-contact-email > p {
        display: flex;
        flex: .3 0;
        justify-content: left;
        margin: 0;
        color: var(--Wheat);
    }

    
    .static-contact-email-input {
      display: flex;
      flex: .6 0;
      align-items: center;
      justify-content: space-between;
      margin-right: 1.57vw;
    }

    .static-contact-email-input > input {
        display: flex;
        min-height: 2.5vh;
        max-height: 2.5vh;
        padding: 0.425vh 0.785vw;
        border-radius: 1.2rem;
        border: none;
        background-color: var(--Wheat);
        color: var(--Umber);
        border-right: 4px solid var(--Umber-Border);
        border-bottom: 4px solid var(--Umber-Border);
    }

    .static-contact-email-input > input[type="Email"] {
      flex: .6 0;
    }  

    .static-contact-email-input > input[type="Name"] {
      flex: .3 0;
    }   

    .static-contact-form-row-2 {
        display: flex;
        align-items: center;
        width: 70%;
        min-height: 10%;
        max-height: 10%;
    }

    .static-contact-subject {    
        border-top: 4px solid var(--Umber-Border);
        border-left: 4px solid var(--Umber-Border);
        display: flex;
        height: calc(100% - 4px);
        min-width: calc(100% - 4px);
        min-height: calc(100% - 4px);
        background-color: var(--Umber);
        border-radius: 1.2rem;
        align-items: center;
        justify-content: center;
    }

    .static-contact-subject > p {
        display: flex;
        flex: .3 0;
        justify-content: left;
        margin: 0;
        color: var(--Wheat);
    }

    .static-contact-subject > input {
      display: flex;
      flex: .6 0;
      border-radius: 1.2rem;
      min-height: 2.5vh;
      max-height: 2.5vh;
      border: none;
      padding: 0.425vh 0.785vw;
      background-color: var(--Wheat);
      color: var(--Umber);
      border-right: 4px solid var(--Umber-Border);
      border-bottom: 4px solid var(--Umber-Border);
    }   


    .static-contact-form-row-3 {
        display: flex;
        flex: .3 0;
        align-items: center;
        width: 70%;
        min-height: 38%;
        max-height: 38%;
    }

    .static-contact-body {
        border-top: 4px solid var(--Umber-Border);
        border-left: 4px solid var(--Umber-Border);
        display: flex;
        min-width: calc(100% - 4px);
        min-height: calc(100% - 4px);
        background-color: var(--Umber);
        border-radius: 1.2rem;
        align-items: center;
        justify-content: center;
    }

    .static-contact-body > p {
        display: flex;
        flex: .3 0;
        margin: 0;
        justify-content: left;
        color: var(--Wheat);
    }

    .static-contact-body > textarea {
        display: flex;
        flex: .6 0;
        max-height: 81%;
        border: none;
        border-right: 4px solid var(--Umber-Border);
        border-bottom: 4px solid var(--Umber-Border);
        padding: 1.0575vh;
        border-radius: 1.2rem;
        resize: none;
        background-color: var(--Wheat);
        color: var(--Umber);
    }

    .static-contact-form-row-4 {
        display: flex;
        align-items: center;
        width: 70%;
        min-height: 10%;
        max-height: 10%;
    }

    .static-contact-submit {
        min-height: calc(100% - 4px);
        max-height: calc(100% - 4px);
    }

    .static-button {
        max-width: calc(100% - 4px);
        max-height: calc(100% - 4px);
        min-width: calc(100% - 4px);
        min-height: calc(100% - 4px);
        border-top: 4px solid var(--Umber-Border);
        border-left: 4px solid var(--Umber-Border);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--Umber);
        color: var(--Wheat);
        border-radius: 1.2rem;
        cursor: pointer;
        border-right: none;
        border-bottom: none;
    }

    .static-button:active  {
        border-top: 2px solid var(--Umber-Border);
        border-left: 2px solid var(--Umber-Border);
    }

    .static-button:active > .static-button-container {
        border-bottom: 2px solid var(--Umber-Border);
        border-right: 2px solid var(--Umber-Border);
    }

    .static-button:active > .static-button-container > p {
        margin-right: 3px !important;
    }

    textarea:focus-visible, input:focus-visible, .static-button:focus-visible {
        outline: none;
    }

    .static-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60%;
        width: 90%;
        background-color: var(--Wheat);
        border-radius: 1.2rem;
        border-bottom: 4px solid var(--Umber-Border);
        border-right: 4px solid var(--Umber-Border);
        margin-left: 5px;
    }

    .noselect {
        color: var(--Black-Olive);
        margin: 0;
        margin-right: 5px;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
    }
}

@media only screen and (max-width: 480px), 
       only screen and (max-height: 480px) {
    .status-flag {
      position: absolute;
      right: 0;
      width: 50%;
      max-height: 0;
    }
    .status-flag > img {
      width: 100%;
    }
    .static-contact {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .static-contact-form {
        background-color: var(--Brown-Sugar);
        border-radius: 2rem;
        display: flex;
        width: 70%;
        max-height: 68%;
        min-height: 67%;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }

    .static-contact-form-row-1 {
        display: flex;
        align-items: center;
        width: 70%;
        min-height: 10%;
        max-height: 10%;
    }

    .static-contact-email {
      border-top: 4px solid var(--Umber-Border);
      border-left: 4px solid var(--Umber-Border);
      display: flex;
      height: auto;
      padding: 0.8rem 0;
      min-width: calc(100% - 4px);
      min-height: calc(100% - 4px);
      background-color: var(--Umber);
      border-radius: 1.2rem;
      align-items: center;
      justify-content: center;
  }
    
    .static-contact-email-input {
      display: flex;
      flex: .6 0;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-right: 1.57vw;
    }

    .static-contact-email-input > input {
        display: flex;
        min-height: 2.5vh;
        max-height: 2.5vh;
        padding: 0.425vh 0.785vw;
        border-radius: 1.2rem;
        border: none;
        background-color: var(--Wheat);
        color: var(--Umber);
        border-right: 4px solid var(--Umber-Border);
        border-bottom: 4px solid var(--Umber-Border);
    }

    .static-contact-email-input > input[type="Email"] {
      flex: .6 0;
      margin-bottom: 0.8rem;
    }  

    .static-contact-email-input > input[type="Name"] {
      flex: .3 0;
    }   

    .static-contact-email > p {
        display: flex;
        flex: .3 0;
        justify-content: left;
        margin: 0;
        color: var(--Wheat);
    }

    .static-contact-email > input {
        display: flex;
        flex: .8 0;
        min-height: 2.5vh;
        max-height: 2.5vh;
        padding: 0.425vh 1.5vw;
        border-radius: 1.2rem;
        border: none;
        background-color: var(--Wheat);
        color: var(--Umber);
        border-right: 4px solid var(--Umber-Border);
        border-bottom: 4px solid var(--Umber-Border);
    }

    .static-contact-form-row-2 {
        display: flex;
        align-items: center;
        width: 70%;
        min-height: 10%;
        max-height: 10%;
    }

    .static-contact-subject {    
        border-top: 4px solid var(--Umber-Border);
        border-left: 4px solid var(--Umber-Border);
        display: flex;
        height: calc(100% - 4px);
        min-width: calc(100% - 4px);
        min-height: calc(100% - 4px);
        background-color: var(--Umber);
        border-radius: 1.2rem;
        align-items: center;
        justify-content: center;
    }

    .static-contact-subject > p {
        display: flex;
        flex: .3 0;
        justify-content: left;
        margin: 0;
        color: var(--Wheat);
    }

    .static-contact-subject > input {
        display: flex;
        flex: .8 0;
        border-radius: 1.2rem;
        min-height: 2.5vh;
        max-height: 2.5vh;
        border: none;
        padding: 0.425vh 1.5vw;
        background-color: var(--Wheat);
        color: var(--Umber);
        border-right: 4px solid var(--Umber-Border);
        border-bottom: 4px solid var(--Umber-Border);
    }   

    .static-contact-form-row-3 {
        display: flex;
        flex: .3 0;
        align-items: center;
        width: 70%;
        min-height: 38%;
        max-height: 38%;
    }

    .static-contact-body {
        border-top: 4px solid var(--Umber-Border);
        border-left: 4px solid var(--Umber-Border);
        display: flex;
        min-width: calc(100% - 4px);
        min-height: calc(100% - 4px);
        background-color: var(--Umber);
        border-radius: 1.2rem;
        align-items: center;
        justify-content: center;
    }

    .static-contact-body > p {
        display: flex;
        flex: .3 0;
        margin: 0;
        justify-content: left;
        color: var(--Wheat);
    }

    .static-contact-body > textarea {
        display: flex;
        flex: .8 0;
        max-height: 81%;
        border: none;
        border-right: 4px solid var(--Umber-Border);
        border-bottom: 4px solid var(--Umber-Border);
        padding: 1.0575vh;
        border-radius: 1.2rem;
        resize: none;
        background-color: var(--Wheat);
        color: var(--Umber);
    }

    .static-contact-form-row-4 {
        display: flex;
        align-items: center;
        width: 70%;
        min-height: 10%;
        max-height: 10%;
    }

    .static-contact-submit {
        min-height: calc(100% - 4px);
        max-height: calc(100% - 4px);
    }

    .static-button {
        max-width: calc(100% - 4px);
        max-height: calc(100% - 4px);
        min-width: calc(100% - 4px);
        min-height: calc(100% - 4px);
        border-top: 4px solid var(--Umber-Border);
        border-left: 4px solid var(--Umber-Border);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--Umber);
        color: var(--Wheat);
        border-radius: 1.2rem;
        cursor: pointer;
        border-right: none;
        border-bottom: none;
    }

    .static-button:active  {
        border-top: 2px solid var(--Umber-Border);
        border-left: 2px solid var(--Umber-Border);
    }

    .static-button:active > .static-button-container {
        border-bottom: 2px solid var(--Umber-Border);
        border-right: 2px solid var(--Umber-Border);
    }

    .static-button:active > .static-button-container > p {
        margin-right: 3px !important;
    }

    textarea:focus-visible, input:focus-visible, .static-button:focus-visible {
        outline: none;
    }

    .static-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60%;
        width: 90%;
        background-color: var(--Wheat);
        border-radius: 1.2rem;
        border-bottom: 4px solid var(--Umber-Border);
        border-right: 4px solid var(--Umber-Border);
        margin-left: 5px;
    }

    .static-button-container > p {
        font-weight: 400 !important;
        font-size: 1rem !important;
        letter-spacing: 1.2px !important;
    }

    .noselect {
        color: var(--Black-Olive);
        margin: 0;
        margin-right: 5px;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
    }
}

