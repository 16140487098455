#flatBooksDiv {
    height: 7%;
    display: flex;
}

.flatBooks {
    height: 100%;
    display: inline-block;
}

.key {
    height: 100%;
    display: inline-block;
}