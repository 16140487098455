@media only screen and (min-width: 480px) { 
    .static-year {
        display: flex;
        align-items: center;
        flex-direction: row;
        position: relative;
        height: auto;
    }

    .static-year-first {
        left: -15px !important;
    }

    .static-year-context {
        position: absolute;
        text-orientation: upright;
        display: flex;
        top: 0px;
        left: -15px;
        height: auto;
        transform: rotate(-90deg);
    }

    .static-year-year {
        font-weight: 600 !important;
        color: var(--Black-Olive);
    }

    .static-year-subheader {
        color: var(--Black-Olive);
    }

    .static-year-vertical {
        display: flex;
        min-width: 2px;
        max-width: 2px;
        border-radius: 5px;
        max-height: 40px;
        min-height: 40px;
        background-color: var(--Black-Olive);
    }

    .static-year-horizontal {
        position: relative;
        display: flex;
        flex: 1 1;
        max-height: 2px;
        min-height: 2px;
        background-color: var(--Black-Olive);
    }   
}

@media only screen and (max-width: 480px),
       only screen and (max-height: 480px) {

    .static-year {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        height: auto;
    }

    .static-year-first {
        left: -15px !important;
    }

    .static-year-context {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 0px;
        left: -15px;
        height: auto;
        transform: rotate(-90deg);
    }

    .static-year-year {
        font-weight: 600 !important;
        color: var(--Black-Olive);
    }

    .static-year-subheader {
        color: var(--Black-Olive);
    }

    .static-year-vertical {
        display: flex;
        flex-direction: column;
        min-height: 2px;
        max-height: 2px;
        border-radius: 5px;
        max-width: 40px;
        min-width: 40px;
        background-color: var(--Black-Olive);
    }

    .static-year-horizontal {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 1;
        max-width: 2px;
        min-width: 2px;
        background-color: var(--Black-Olive);
    }   
}