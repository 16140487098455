@media only screen and (min-width: 480px) {
    
    .static-nav {
        display: flex;
        height: auto;
        justify-content: center;
        align-items: center;
        padding: 0.75rem 0;
    }

    .static-nav-header { 
        position: sticky;
        max-height: 10%;
        min-height: 10%;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex: 1 1;
        justify-content: center;
    }

    .static-nav-header-container {
        display: flex;
        margin: 0;
        align-items: flex-end;
    }

    .static-nav-title {
        height: fit-content;
        color: var(--Black-Olive);
        justify-content: center;
        align-items: center;
        display: flex;
        margin-right: 1rem !important;
    }

    .static-nav-pages {
        display: flex;
        align-items: center;
    }

    .static-nav-page-link {
        height: fit-content;
        cursor: pointer;
        text-decoration: underline;
        color: var(--Black-Olive);
        justify-content: right;
        align-items: center;
        display: flex;
        margin: 0 !important;
        margin-right: 1rem !important;
    }

    .static-nav-page-about-me-wrapper {
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        cursor: pointer;
    }

    .static-nav-page-about-me-wrapper > h3 {
        text-decoration: underline;
    }

    .static-nav-page-about-me-wrapper:hover .static-nav-page-about-me-dropdown {
        display: flex;
        height: 100%;
        visibility: visible;
        opacity: 1;
    } 

    .static-nav-page-about-me-dropdown {
        display: none;
        visibility: hidden;
        height: 0%;
        opacity: 0;
        flex-direction: column;
        position: absolute;
        top: 100%;
        text-align: left;
        transition: height     .3s ease-out,
                    visibility .3s ease-out,
                    display    .3s ease-out,
                    opacity    .3s ease-out;
    }

    .static-nav-page-about-me-dropdown > h5 {
        cursor: pointer;
    }

    .static-nav-page-about-me-dropdown > h5:hover {
        text-decoration: underline;
    }

    .static-nav-page-link a {        
      color: black !important;
      display: flex;
      text-decoration: none;
      height: auto;
      line-height: 1.8rem;
    } 
}
@media only screen and (max-width: 480px), 
       only screen and (max-height: 480px) {

    .static-nav {
        transition: width .3s ease-in-out,
                    height .3s ease-in-out,
                    background-color .1s ease-out;
        z-index: 5;
        position: absolute;
        width: 5rem;
        height: 5rem;
        background-color: var(--Wheat);
    }

    .static-nav[data-drawer-active="true"] {
        width: 100%;
        height: 100%;
        background-color: var(--Sage);
        transition: width .3s ease-in-out,
                    height .3s ease-in-out,
                    background-color .1s ease-out;
    }

    .static-nav[data-current-page="true-AboutMe"] {
        background-color: var(--Sage);
    }

    .static-nav[data-current-page="true-Contact"] {
        background-color: var(--Brown-Sugar);
    }

    .static-nav[data-current-page="true-ProgrammingTimeLine"] {
        background-color: var(--Sage);
    }

    .static-nav-hamburger-container[data-drawer-active="false"] {
        min-width: 5rem;
    }

    .static-nav-hamburger-container {
        display: flex;
        min-height: 5rem;
        justify-content: flex-end;
        align-items: center;
    }

    .static-nav-hamburger {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 0.6rem;
        margin-left: 1.4rem;
        transition: transform .3s ease-in-out 0s;
    }

    .static-nav-hamburger-top {
        align-self: flex-start;
        justify-content: flex-start;
    }

    .static-nav-hamburger-middle {
        transition: width .3s ease-in-out 0s,
                    transform .3s ease-in-out 0s;
        transform-origin: 50% 50%;
    } 

    .static-nav-hamburger-bottom {
        margin-bottom: 0 !important;
        align-self: flex-start;
    }

    .static-nav-hamburger[data-drawer-active="true"] {
        transform: rotate(90deg);
        transform-origin: 62% 62%;
    }

    .static-nav-hamburger-middle[data-drawer-active="true"] {
        transform: rotate(-35deg);
        transform-origin: 50% 50%;
        width: 2.6rem;
        height: 0.15rem;
        margin-left: -0.2rem;
    }

    .static-nav-hamburger-line {
        border-radius: 2.6rem;
        width: 2.2rem;
        height: .15rem;
        margin-top: 8px !important;
        margin-bottom: .6rem;
        margin-right: 0;
        background-color: var(--Black-Olive);
    }

    .static-header { 
        display: flex;
        flex: 1 1;
        background-color: var(--Sage);
        min-height: 100%;
        min-width: 100%;
        z-index: 5;
        position: sticky;
        top: 0;
        left: 0;
        justify-content: center;
    }

    .static-nav-header-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin: 0;
        align-items: center;
        justify-content: center;
    }

    .static-nav-title-container {
        display: flex;
        height: auto;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        z-index: 1;    
    }

    .static-nav-title {
        position: absolute;
        left: 60px;
        bottom: 20px;
        min-width: max-content;
        height: 2.1rem !important;
        padding-bottom: 0.1rem;
        justify-content: left;
        color: var(--Black-Olive);
        align-items: center;
        display: flex;
        margin: 0 2vw 0 0;
    }
    .static-nav-title[data-is-active="false"] {
      /* transition: opacity 3s ease-in-out; */
      opacity: 0;
    }
    .static-nav-title[data-is-active="true"] {
      transition: opacity 1s ease-in-out;
      opacity: 1;
    }
    .static-nav-page-link {
        width: 100%;
        flex: .1 0;
        height: fit-content;
        cursor: pointer;
        text-decoration: underline;
        color: var(--Black-Olive);
        justify-content: center;
        align-items: center;
        display: flex;
        margin: 0;
        margin-right: 2vw;
    }

    .static-nav-page-about-me-wrapper {
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        flex: .1 0;
    }

    .static-nav-page-about-me-wrapper > h3 {
        text-decoration: none;
        flex: .1 0;
    }

    .static-nav-page-about-me-dropdown {
        display: flex;
        flex: .1 0;
        flex-direction: column;
        max-width: 35%;
        transition: height     .3s ease-out,
                    visibility .3s ease-out,
                    display    .3s ease-out,
                    opacity    .3s ease-out;
    }

    .static-nav-page-about-me-dropdown > h5 {
        cursor: pointer;
        text-decoration: underline;
        text-align: left;
    }

    .static-nav-mobile-parent {
        display: flex;
        align-items: center;
    }
    .static-nav-mobile-backarrow {
        width: 2rem;
    }

    .static-nav-pages {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 0;
    }
    .static-nav-page-link a{
      color: black !important;
    }
}

