@media only screen and (min-width: 480px) {
    .static-aboutme {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .static-aboutme-container {
        background-color: var(--Sage);
        border-radius: 1.2rem;
        display: flex;
        width: 70%;
        max-height: 68%;
        min-height: 67%;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }

    .static-about-me-rows {
        border-top: 4px solid var(--Umber-Border);
        border-left: 4px solid var(--Umber-Border);
        display: flex;
        background-color: var(--Umber);
        border-radius: 1.2rem;
        width: 70%;
        justify-content: space-evenly;
        align-items: center;
    }

    .static-about-me-row-1 {
        flex: .15 0;
    }

    .static-about-me-row-2 {
        flex: .45 0;
    }

    .static-about-me-row-3 {
        flex: .1 0;
        border: none;
    }

    .static-about-me-col {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 4px solid var(--Umber-Border);
        border-right: 4px solid var(--Umber-Border);
        border-radius: 1.2rem;
        background-color: var(--Wheat);
    }

    .static-about-me-ul {
        margin: 0;
    }

    .static-about-me-col-long {
        flex: .9 0;
        align-items: center;
        justify-content: center;
        max-height: 60%;
    }

    .static-about-me-col-long > h3 {
        margin: 0;
    }

    .static-about-me-col-small {
        flex: .25 0;
        justify-content: center;
        align-items: center;
        max-height: 80%;
    }

    .static-about-me-col-small > p {
        margin: 0;
    }

    .static-about-me-col-big {
        flex: .65 0;
        align-items: center;
        max-height: 80%;
    }

    .static-about-me-col-big > p {
        text-align: left;
        padding: 0 2%;
    }
}

@media only screen and (max-width: 480px), 
       only screen and (max-height: 480px) {
    .static-aboutme {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .static-aboutme-container {
        background-color: var(--Sage);
        border-radius: 1.2rem;
        display: flex;
        width: 70%;
        max-height: 68%;
        min-height: 67%;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }

    .static-about-me-rows {
        display: flex;
        flex-direction: column;
        border-radius: 1.2rem;
        width: 70%;
        justify-content: space-evenly;
        align-items: center;
    }

    .static-about-me-row-1 {
        border-top: 4px solid var(--Umber-Border);
        border-left: 4px solid var(--Umber-Border);
        background-color: var(--Umber);
        flex: .1 0;
    }

    .static-about-me-row-2 {
        border-top: 4px solid var(--Umber-Border);
        border-left: 4px solid var(--Umber-Border);
        background-color: var(--Umber);
        flex: .55 0;
    }

    .static-about-me-row-3 {
        flex: .1 0;
        width: 70%;
        min-height: 10%;
        max-height: 10%;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .static-about-me-col {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 4px solid var(--Umber-Border);
        border-right: 4px solid var(--Umber-Border);
        border-radius: 1.2rem;
        background-color: var(--Wheat);
    }

    .static-aboutme .static-button:active > .static-button-container > p {
        margin-top: 2px !important;
    }

    .static-about-me-ul {
        margin: 0;
        padding-left: 1.5rem;
    }

    .static-about-me-col-long {
        flex: .9 0;
        align-items: center;
        justify-content: center;
        max-height: 60%;
        width: 80%;
    }

    .static-about-me-col-long > h3 {
        margin: 0;
    }

    .static-about-me-col-small {
        flex: .2 0;
        width: 80%;
        justify-content: center;
        align-items: center;
        max-height: 80%;
    }

    .static-about-me-col-small > p {
        margin: 0;
        font-size: .8rem !important;
    }

    .static-about-me-col-big {
        flex: .7 0;
        width: 80%;
        align-items: center;
        max-height: 80%;
    }

    .static-about-me-col-big > p {
        text-align: left;
        padding: 0 2%;
    }
}