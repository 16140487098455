.woodPlatform {
    position: absolute;
    height: 7%;
}


/* #wood1 {
    bottom: 170px;
    left: 300px;
} */
#wood2 {
    bottom: 300px;
    left: 510px;
}
#wood3 {
    bottom: 420px;
    left: 710px;
}
