html {
  height: 100%;
  width: 100%;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

div {
  height: 100%;
}

* {
  font-family: 'QuickSand';
}

.page-switch-button {
  position: fixed;
  right: 0;
  top: 0;
  width: 80px;
  height: 40px;
  background-color: var(--Brown-Sugar);
  margin: 15px;
  border-radius: 5px;
  z-index: 1;
  cursor: pointer;
}


.switch {
  width: 57px;
  height: 25px;
  background-color: var(--Brown-Sugar);
  display: flex;
  justify-content: flex-start;
  border-radius: 40px;
  padding: 5px 5px 10px 5px;
  cursor: pointer;
}

.switch[data-ison="true"] {
  justify-content: flex-end;
}

.handle {
  width: 30px;
  height: 30px;
  background-color: var(--Wheat);
  border-radius: 40px;
}


@media only screen and (max-width: 480px), 
       only screen and (max-height: 480px) {

  .page-switch-button {
    display: None; 
  }

}