#promptDiv {
    border-radius: 12px;
    position: absolute;
    opacity: 90%;
    background-color: rgb(45, 45, 45);
    top: 40%;
    height: 20%;
    width: 30%;
}

#promptDivChild {
    border-radius: 12px;
    position: relative;
    width: 100%;
    height: 100%;
}

#promptDivChild > h1 {
    border-radius: 12px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
}