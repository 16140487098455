@media only screen and (min-width: 480px) { 
  .colored-border {
    background-color: var(--Brown-Sugar);
    border-radius: 1.2rem;
    display: flex;
    width: 40%;
    max-height: 95%;
    min-height: 95%;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .resume-pdf-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 95%;
  }
  .resume-pdf-container > div {
    display: flex;
  }
  .resume-pdf-container > div > div {
    width: 100%;
  }
  .resume-pdf-container .rpv-core__inner-page-container {
    display: flex;
    justify-content: center;
  }
  .resume-pdf-container .rpv-core__inner-page {
    width: auto !important;
    position: unset !important;
  }
  .download-button {
    display: flex;
  }

  .resume-static-button {
    max-width: calc(50% - 4px) !important;
    max-height: 5% !important;
    min-width: calc(50% - 4px) !important;
    min-height: 5% !important;
  }
  .resume-static-button-container {
    width: 90% !important;
  }
}


@media only screen and (max-width: 480px), 
       only screen and (max-height: 480px) {

  .colored-border {
    background-color: var(--Brown-Sugar);
    border-radius: 1.2rem;
    display: flex;
    width: 90%;
    min-height: max-content;
    height: auto;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .resume-pdf-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem 0px;
  }
  .resume-pdf-container > div {
    display: flex;
  }
  .resume-pdf-container > div > div {
    width: 100%;
  }
  .resume-pdf-container .rpv-core__inner-page-container {
    display: flex;
    justify-content: center;
  }
  .resume-pdf-container .rpv-core__inner-page {
    width: auto !important;
    position: unset !important;
  }
  .resume-static-button {
    margin-top: 1rem;
    max-width: calc(40% - 4px) !important;
    max-height: 10% !important;
    min-width: calc(40% - 4px) !important;
    min-height: 10% !important;
  }
  .resume-static-button-container {
    width: 90% !important;
  }
}