@media only screen and (min-width: 480px) {
    .static-mobile {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        max-height: 90%;
    }

    .static-game-row {
        align-items: center;
        justify-content: space-evenly;
    }

    .static-game-row-1 {
        display: flex;
        flex: .5 0;
        justify-content: center;
        align-items: flex-end;
    }

    .static-game-col {
        border-radius: 2rem;
        background-color: var(--Sage);
    }

    .static-game-col-1-1 {
        border-top: 4px solid var(--Umber-Border);
        border-left: 4px solid var(--Umber-Border);
        margin-right: 2.1rem !important;
        display: flex;
        flex: .5 0;
        height: 100%;
    }

    .static-game-col-1-2 {
        border-top: 4px solid var(--Umber-Border);
        border-left: 4px solid var(--Umber-Border);
        display: flex;
        flex: .3 0;
        height: 80%;
    }

    .static-game-row-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: .5 0;
    }

    .static-game-col-2-1 {
        border-top: 4px solid var(--Umber-Border);
        border-left: 4px solid var(--Umber-Border);
        display: flex;
        flex: .6;
        height: 80%;
    }
    .static-mobile-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .static-mobile-title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5%;
        max-height: 12%;
        min-height: 12%;
        min-width: 70%;
        max-width:70%;
        background-color: var(--Wheat);
        border-radius: 1.2rem;
        border-bottom: 4px solid var(--Umber-Border);
        border-right: 4px solid var(--Umber-Border);
    }

    .static-mobile-title h3 {
        display: flex;
        min-width: max-content;
        margin: 0;
        padding: .25rem;
    }

    .static-mobile-content {
        max-width: 100%;
        display: flex;
    }

    .static-mobile-how-img {
        display: flex;
        flex: .5 .5;    
        justify-content: center;
        align-items: center;
    }
    
    .static-mobile-how-img img {
        display: flex;
        object-fit: fill;  
        max-height: 90%;
        min-height: 90%;
        min-width: 90%;
        max-width: 90%;
        border-radius: 1.2rem;
        border-bottom: 4px solid var(--Umber-Border);
        border-right: 4px solid var(--Umber-Border);
    }
    .static-mobile-how-text {
        display: flex;
        flex: .5 .5;
        align-items: center;
        justify-content: center;
    }
    .static-mobile-how-text-container {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 90%;
        min-height: 90%;
        min-width: 90%;
        max-width: 90%;
        background-color: var(--Wheat);
        border-radius: 1.2rem;
        border-bottom: 4px solid var(--Umber-Border);
        border-right: 4px solid var(--Umber-Border);
    }
    .static-mobile-how-text p {
        display: flex;
        text-align: left;
        height: min-content;
        padding: 2%;
    }
}