
#playerOutside {
    position: absolute;
    height: 12%; 
}

#playerInterior {
    position: absolute;
    height: 25%;
    bottom: 10% !important;
}

#playerBasement {
    position: absolute;
    height: 25%;
    bottom: 10% !important;
}
