@media only screen and (min-width: 480px) {
    .static-timeline {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        margin: auto 0 1.5rem 0;        
        overflow-x: auto;
    }
    .static-timeline-years {
        display: flex;
        flex: .2 0;
        position: relative;
        justify-content: center;
        align-items: flex-start;
    }
    .static-timeline::-webkit-scrollbar {
        display: none;
    }
    .static-timeline-events {
        margin: 0 auto;
        padding: 0 1.5rem;
        flex: .8 0;
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .static-event-tracker {
        position: absolute;
        left: 0;
        border-radius: 2rem;
        height: 2.9rem;
        width: 0.75rem;
        transition: left .3s;
        background-color: var(--Black-Olive);
    }
}

@media only screen and (max-width: 480px),
       only screen and (max-height: 480px) {
    .static-timeline {
        margin-top: 5rem;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        width: 100%;
        height: calc(100% - 5rem);
    }
    .static-timeline-years {
        display: flex;
        position: relative;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
    }
    .static-event-tracker {
        position: absolute;
        top: 0;
        border-radius: 2rem;
        width: 2.9rem;
        height: 0.75rem;
        transition: top .3s;
        background-color: var(--Black-Olive);
    }
    .static-timeline-events {
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
    }
}