#Basement {
    height: 100%;
    width: 100%;
    background-color: black;
}

#BasementIMG {
    height: 100%;
}

#leaveBasement {
    position: absolute;
    right: 18%;
    top: 6%;
}

#Studies {
    height: 10%;
    bottom: 34%;
    left: 69%;
}