.projects {
    overflow-y: auto;
    height: 100%;
}

.project-title {
    height: 40px;
    margin: 0;
    font-size: 40px;
    border-radius: 12px 12px 0 0;
    font-weight: 400;
    padding: 30px 0;
    background-color: rgb(150, 150, 150);
}

.project-info {
    overflow-y: auto;
    width: 100%;
    border-radius: 12px;
    height: calc(95% - 100px);
    background-color: rgb(200, 200, 200);
}

.project-x {
    position: absolute;
    right: 30px;
    height: 40px;
    top: 30px;
    cursor: pointer;
}

.project {
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: auto;
}

.project-header {
    display: inline;
}

.packJet-header {
    margin-top: 20px;
}

.packJet-title {
    display: inline;
    margin: 0;
}

.packJet-title-desc {
    color:rgb(100, 100, 100);
    display: inline;
}

.ECS-title {
    display: inline;
    margin: 0;
}

.ECS-title-desc {
    color:rgb(100, 100, 100);
    display: inline;
}

.project-content {
    text-align: left;
    width: 70%;
    padding: 0 15%;
}
.new-project-hr {
    width: calc(100% - 152px);
    margin: 0 75px 20px 75px;
}

.projects-footer {
    height: 5%;
    width: 100%;
    border-radius: 0 0 12px 12px;
    background-color: rgb(150, 150, 150);
}