#Interior {
    height: 100%;
    width: 100%;
    background-color: black;
}

#InteriorIMG {
    height: 100%;
}

#leaveInterior {
    position: absolute;
    right: 18%;
    top: 6%;
}

#projects {
    height: 10%;
    bottom: 23%;
    left: 50%;

}
