.experience {
    border-radius: 12px 12px 0 0;
    display: flex;
    flex-direction: column;
}

.experience-title {
    height: 40px;
    margin: 0;
    font-size: 40px;
    border-radius: 12px 12px 0 0;
    font-weight: 400;
    padding: 30px 0;
    background-color: rgb(150, 150, 150);
}

.experience-x {
    position: absolute;
    right: 30px;
    height: 40px;
    top: 30px;
    cursor: pointer;
}

.experience-info {
    height: calc(95% - 100px);
    display: flex;
    flex-direction: column;
    flex: 2 0;
    overflow-y: auto;
    border-radius: 12px;
    width: 100%;
    background-color: rgb(200, 200, 200);
}

/* width */
.info-div::-webkit-scrollbar {
    width: 12px;
}

/* Track */
.info-div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 0 12px 12px 0;
}

/* Handle */
.info-div::-webkit-scrollbar-thumb {
    background: rgb(150, 150, 150); 
    border-radius: 0 12px 12px 0;
}

/* Handle on hover */
.info-div::-webkit-scrollbar-thumb:hover {
    background: rgb(75, 75, 75); 
}

li {
    text-align: left;
}

.experience h3 {
    margin: 0;
}

.experience-row {
    display: block;
}

.experience-row1 {
    margin-bottom: 10px;
    display:flex;
    flex-direction: row;
    flex: .55 0 auto;
    height: auto;
    padding-top: 20px;
}

.vanguard-col1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: .25 0 0;
    max-width: 25%;
    float: left;
}

.vanguard-col2 {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    flex: .75 0 0;
    text-align: left;
    width: 70%;
    float: right;
}

.ship-div {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 0 .5 0;
}

.ship-img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

.logo-div {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 0 .5 0;
}

.logo-img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}


.experience-row2 {
    display: flex;
    flex-direction: row;
    flex: 1 0;
    text-align: center;
    height: auto;
}

.voya-col1 {
    text-align: left;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    flex: 70% 0;

}

.voya-col2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 30% 0;

}

.voya-img {
    display: flex;
    align-items: center;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

.experience-row2 h1 {
    margin: 0;
    height: 40px;
}
.experience-row1 p, 
.experience-row2 p,
.experience-row3 p, 
.experience-row4 p,
.experience-row5 p {
    line-height: 18px;
    height: 18px;
    padding-top: 5px;
    margin: 0;
}

.experience-row1 ul, 
.experience-row2 ul,
.experience-row4 ul,
.experience-row5 ul {
    margin: 0;
}

.additional-header h3, .additional-header p {
    display: inline-block;
    padding-left: 5px;
}

.additional-exp {
    margin-top: 0;
}

.experience-row4 {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    text-align: center;
}

.experience-row3 {
    display: flex;
    flex-direction: column;
}
.experience-hr {
    margin: 20px 75px 20px 75px; 
    width: calc(100% - 152px);
}

.experience-row5 ul,
.experience-row4 ul {
    padding-left: 15%;
    width: 70%;
}


.experience-row5 {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    padding-top: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.experience-footer {
    height: 5%;
    width: 100%;
    border-radius: 0 0 12px 12px;
    background-color: rgb(150, 150, 150);
}