@media only screen and (min-width: 480px) {
    .static-contact-motion {
      position: relative;
    }
    * {
        --Black-Olive-Active: #252721;
        --Black-Olive: #292B24;
        --Sage: #B9C289;
        --Dark-Sage: #9EA777;
        --Wheat: #FFEFD2;
        --Brown-Sugar: #C7774E;
        --Umber: #5E4C43;
        --Umber-Border: #3e332d;
    }

    .static h1 {
        font-family: "QuickSand";
        font-weight: 400;
        font-size: 3.4rem;
        height: 2.5rem;
        letter-spacing: -3px;
    }

    .static h3 {
        font-family: "QuickSand";
        font-weight: 400;
        font-size: 1.7rem; 
        letter-spacing: -2px;
    }

    .static h5 {
        width: max-content;
        font-family: "QuickSand";
        font-weight: 400;
        line-height: 1rem;
        font-size: 1rem; 
        letter-spacing: 1.2px;
        margin: 0;
    }

    .static p {
        font-family: "QuickSand";
        font-weight: 500;
        font-size: 1.7vmin;   
        letter-spacing: 1.2px;
    }

    .static {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: var(--Wheat);
    }

    .static-game-parent {
        display: flex;
        align-items: center;
    }

    .static-motion {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 480px), 
       only screen and (max-height: 480px) {
    * {
        --Black-Olive-Active: #252721;
        --Black-Olive: #292B24;
        --Sage: #B9C289;
        --Dark-Sage: #A7B07D;
        --Wheat: #FFEFD2;
        --Brown-Sugar: #C7774E;
        --Umber: #5E4C43;
        --Umber-Border: #3e332d;
    }

    .static h1 {
        font-family: "QuickSand";
        font-weight: 400;
        font-size: 3rem;
        height: 2.5rem;
        letter-spacing: -3px;
    }

    .static h3 {
        font-family: "QuickSand";
        font-weight: 400;
        font-size: 1.7rem; 
        letter-spacing: -2px;
    }

    .static h5 {
        font-family: "QuickSand";
        font-weight: 400;
        font-size: 1rem; 
        letter-spacing: 1.2px;
        margin: 0;
    }

    .static p {
        font-family: "QuickSand";
        font-weight: 500;
        font-size: 1.7vmin;   
        letter-spacing: 1.2px;
    }

    .static {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: var(--Wheat);
    }

    .static-mobile-parent {
        display: flex;
        align-items: center;
    }
    .static-mobile-backarrow {

    }
    
    .static-motion {
        display: flex;
        flex-direction: column;
    }
}

