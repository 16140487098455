.interests {
    width: 100%;
    height: 100%;
}

.interest-title {
    height: 40px;
    margin: 0;
    font-size: 40px;
    border-radius: 12px 12px 0 0;
    font-weight: 400;
    padding: 30px 0;
    background-color: rgb(150, 150, 150);
}

.interest-x {
    position: absolute;
    right: 30px;
    height: 40px;
    top: 30px;
    cursor: pointer;
}

.interest-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    background-color: rgb(200, 200, 200);
    border-radius: 12px;
    height: calc(95% - 100px);
}

.interests-info h2 {
    margin: 0;
}

.interests-info p {
    margin: 0;
}

.Interest_rows {
    text-align: left;
    width: 100%;
}

.interest-row1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 20px;
    height: auto;
}

.interest-row2 {
    height: auto;
    text-align: center;
    margin-bottom: 20px;
}

.interest-col1 {
    display: flex;
    flex: 1 0 0;
    min-width: 500px;
    text-align: left;
    height: auto;
}

.interest-col2 {
    display: flex;
    flex: 1 0 0;
    height: auto;
    min-width: 500px;
    text-align: left;
}

.music-desc {
    margin: auto 0;
    padding: 0 20px 0 20px;
    height: auto;
    display: flex;
    flex-direction: column;
    flex: 70% 0;
}

.music-desc > h2 {
    text-align: center;
}

.music-img {
    padding-left: 20px;
    display: flex;
    justify-content: center;
    flex: 30% 0;
}


.music-img > img {
    padding-top: 20px;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.fitness-desc {
    flex-direction: column;
    margin: auto 0;
    padding: 0 20px 0 20px;
    display: flex;
    flex: 70% 0;
    height: auto;
}

.fitness-desc > h2 {
    text-align: center;
}

.fitness-img {
    padding-right: 20px;
    display: flex;
    justify-content: center;
    flex: 30% 0;
}

.fitness-img > img {
    padding-top: 20px;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.coding-desc {
    margin: 0 auto;
    padding: 20px 0 0 0 ;
    width: calc(90% - 20px);
    height: auto;
}

.interest-footer {
    height: 5%;
    width: 100%;
    border-radius: 0 0 12px 12px;
    background-color: rgb(150, 150, 150);
}