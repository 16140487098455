.contact {
    width: 100%;
    height: 100%;
}

.api-resp-message {
    position: absolute;
    top: -5px;
    right: 0;
    max-height: 23%;
    max-width: 23%;
    min-width: 165px;
    object-fit: contain;
}

.contact-title {
    height: 40px;
    margin: 0;
    font-size: 40px;
    border-radius: 12px 12px 0 0;
    font-weight: 400;
    padding: 30px 0;
    background-color: rgb(150, 150, 150);
}

.contact-x {
    position: absolute;
    right: 30px;
    height: 40px;
    top: 30px;
    cursor: pointer;
}

.contact-info {
    position: relative;
    overflow-y: auto;
    border-radius: 12px;
    height: calc(95% - 100px);
    width: 100%;
    background-color: rgb(200, 200, 200);
}

.email-div {
    padding-top: 5px;
    padding-left: 10%;
    width: 90%;
    height: 45px;
}

.email-label {
    display: flex;

}

.email-input {
    display: flex;
    border-radius: 3px;
    width: 24%;
    height: 20px;
}

.subject-div {
    padding-top: 5px;
    padding-left: 10%;
    width: 90%;
    height: 45px;
}

.subject-label {
    display: flex;
    width: 100%;
    height: 20px;
}

.subject-input {
    display: flex;
    border-radius: 3px;
    width: 56%;
    height: 20px;
}

.body-div {
    padding-top: 5px;
    display: inline-block;
    height: calc(100% - 145px);
    width: 100%;
}

.body-label {
    text-align: left;
    padding-left: 10%;
    display: block;
}

.body-input {
    border-radius: 9px;
    height: 90% !important;
    width: 80% !important;
    resize: none;
}

.submit-div {
    justify-content: center;
    display: flex;
    height: auto;
}

.submit-button {
    border-radius: 6px;
    height: 30px;
    width: 100px;
}

.contact-footer {
    height: 5%;
    width: 100%;
    border-radius: 0 0 12px 12px;
    background-color: rgb(150, 150, 150);
}