.skills-title {
    height: 40px;
    margin: 0;
    font-size: 40px;
    border-radius: 12px 12px 0 0;
    font-weight: 400;
    padding: 30px 0;
    background-color: rgb(150, 150, 150);
}

.skills-x {
    position: absolute;
    right: 30px;
    height: 40px;
    top: 30px;
    cursor: pointer;
}

.skills-info {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: calc(95% - 100px);
    border-radius: 12px;
    background-color: rgb(200, 200, 200);
}

.skills-info ul {
    width: 50%;
    padding-left: 25%;
    margin: 0;
}

.skills-row1 {
    display: flex;
    height: auto;
    padding-top: 20px;
}

.skills-row1 h1 {
    margin: 0;
}

.skills-leftside {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.skills-rightside {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.skills-row2 {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    height: auto;
}

.skills-row2 h2 {
    margin: 0;
    padding-bottom: 20px;
}
.skills-hr {
    margin: 20px 75px; 
    width: calc(100% - 152px);
}

.resume {
    color: #8C5817;
}

.resume:hover {
    color: #AD6D1D;
}

.skills-row2 p {
    line-height: 18px;
    height: auto;
    padding-top: 5px;
    margin: 0;
}

.skills-footer {
    height: 5%;
    width: 100%;
    border-radius: 0 0 12px 12px;
    background-color: rgb(150, 150, 150);
}
