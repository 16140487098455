#DPad > img {
    position: absolute;
}

#DPad {
    height: 46px;
    width: 126px; 
    left: 0;
    bottom: 0;
} 

#JoyStick{ 
    height: 46px;
    width: 32px;
}
