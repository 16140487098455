
#OutsideIMG {
    height: 100%;
    white-space: nowrap;
    line-height: 0;
}
#OutsideIMG> img {
    display: inline-block;    
    height: 100%;
}

#keyBasement {
    height: 10%;
    position: absolute;
    bottom: 60%;
    /* left: 83%; */
}