#boat {
    position: absolute;
    left: 1000px;
    bottom: 90px;
    height: 500px;
}

#house {
    position: absolute;
    height: 50%;
}