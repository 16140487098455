.studies {
    width: 100%;
    height: 100%;
}

.studies-title {
    height: 40px;
    margin: 0;
    font-size: 40px;
    border-radius: 12px 12px 0 0;
    font-weight: 400;
    padding: 30px 0;
    background-color: rgb(150, 150, 150);
}

.studies-x {
    position: absolute;
    right: 30px;
    height: 40px;
    top: 30px;
    cursor: pointer;
}

.studies-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    border-radius: 12px;
    width: 100%;
    height: calc(95% - 100px);
    background-color: rgb(200, 200, 200);
}

.studies-leftside {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    min-width: 500px;
}

.WSU-years-title, .RIT-years-title {
    margin: 0;
}

.studies-rightside {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    min-width: 500px;
}

.college-info {
    padding-top: 20px;
    text-align: left;
    padding-left: 10%;
}
.college-info p {
    margin: 0;
    height: auto;
    padding-left: 40px;
    line-height: auto;
}
.titles {
    margin-bottom: 0;
}

.college-info ul {
    margin: 0;
}

.college-logo {
    padding-top: 20px;
    height: 30%
}

.college-logo img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

.WSU-logo {
    filter: brightness(150%);
}

.studies-footer {
    height: 5%;
    width: 100%;
    border-radius: 0 0 12px 12px;
    background-color: rgb(150, 150, 150);
}